
import MBaseModal from "@/components/MBaseModal.vue";
import MButton from "@/components/MButton.vue";
import MIcon from "@/components/MIcon.vue";
import MSelectBox from "@/components/form/MSelectBox.vue";
import MTextArea from "@/components/form/MTextArea.vue";
import MTextField from "@/components/form/MTextField.vue";
import { Group } from "@/entities/group";
import store from "@/store";
import dayjs from "dayjs";
import { Options, Vue } from "vue-class-component";
import { getWeekdayStr } from "@/utils/date";
import { RoomRegularlyReservation } from "@/entities/room_regularly_reservation";
import {
  deleteRoomRegularlyReservation,
  updateRoomRegularlyReservation
} from "@/api/room_reservation";
import { saveErrorLog } from "@/api/error";
import { State } from "@/store/helper";
import { Label } from "@/entities/label";

type RegularPatternOption = {
  id: "day" | "week" | "month";
  text: string;
};

@Options({
  components: {
    MBaseModal,
    MButton,
    MIcon,
    MSelectBox,
    MTextArea,
    MTextField
  },
  emits: ["close"],
  props: {
    roomRegularlyReservation: Object,
    groups: Array
  }
})
export default class MrEditRoomRegularlyReservationModal extends Vue {
  roomRegularlyReservation!: RoomRegularlyReservation;
  selectedGroupId = "";
  selectedLabelId = "";
  groups: Group[] = [];
  name = "";
  withNotification = false;
  reserveDay = "";
  reserveTime = "18:00";
  endReserveTime = "";
  existDeletionTime = false;
  deletionDay = "";
  selectedRegularPattern: "day" | "week" | "month" = "day";

  @State("labels", "room") labels!: Label[];

  get regularPatternOptions(): RegularPatternOption[] {
    const dayData = dayjs(this.reserveDay).locale("ja");
    const monthText = `毎月${dayData.date()}日`;
    const weekText = `毎週${getWeekdayStr(dayData.day())}曜日`;
    return [
      {
        id: "month",
        text: monthText
      },
      {
        id: "week",
        text: weekText
      },
      {
        id: "day",
        text: "毎日"
      }
    ];
  }

  get selectedLabel(): Label | null {
    return (
      this.labels.find(label => label.ref.id === this.selectedLabelId) ?? null
    );
  }

  get isValidName() {
    return this.name.length > 0;
  }

  get isValidReserveDay(): boolean {
    return /^\d{4}-\d{2}-\d{2}$/.test(this.reserveDay);
  }

  get isValidReserveTime(): boolean {
    return /^\d{2}:\d{2}$/.test(this.reserveTime);
  }

  get isValidReserve() {
    return this.isValidReserveDay && this.isValidReserveTime;
  }

  get isValidData() {
    return this.isValidName && this.isValidReserve && !!this.endReserveTime;
  }

  async updateRoomRegularlyReservation() {
    if (!this.isValidData) {
      return;
    }

    // 定期的な開室予約の学習室開催で開室時間より閉室時間の方が小さかったら、アラートを表示して早期リターン(深夜の開催は考慮しない)
    if (
      Number(this.reserveTime.replace(/:/g, "")) >
      Number(this.endReserveTime.replace(/:/g, ""))
    ) {
      confirm("閉室予定時間は開室予定時間より後の時間を設定してください。");
      this.endReserveTime = "";
      return;
    }

    const matchGroups = this.groups.filter(
      group => group.ref.id === this.selectedGroupId
    );

    const reservationDateData = dayjs(
      `${this.reserveDay} ${this.reserveTime}`
    ).locale("ja");
    const reservationTime = reservationDateData.unix();

    const deletionTime = this.existDeletionTime
      ? dayjs(this.deletionDay).locale("ja").add(1, "day").unix()
      : undefined;

    store.commit("START_LOADING", "更新中...");

    try {
      await updateRoomRegularlyReservation(
        this.roomRegularlyReservation.ref,
        this.name,
        matchGroups.length > 0 ? matchGroups[0].data.title : "全ての生徒",
        this.withNotification,
        reservationTime,
        this.selectedRegularPattern as "day" | "week" | "month",
        this.reserveTime,
        this.endReserveTime ?? null,
        this.selectedLabel ? [this.selectedLabel.ref] : [],
        reservationDateData.day(),
        reservationDateData.date(),
        deletionTime,
        matchGroups.length > 0 ? matchGroups[0].ref : undefined
      );
    } catch (e) {
      store.commit("END_LOADING");
      alert(e.message);
      await saveErrorLog(
        store.state.role,
        e.code,
        e.message,
        "Failed to update room regularly reservation"
      );
      return;
    }

    store.commit("END_LOADING");

    this.$router.go(0);
  }

  async deleteRoomRegularlyReservation() {
    store.commit("START_LOADING", "削除中...");
    try {
      await deleteRoomRegularlyReservation(this.roomRegularlyReservation.ref);
    } catch (e) {
      store.commit("END_LOADING");
      alert(e.message);
      await saveErrorLog(
        store.state.role,
        e.code,
        e.message,
        "Failed to delete room regularly reservation"
      );
    }
    store.commit("END_LOADING");

    this.$router.go(0);
  }

  created() {
    this.name = this.roomRegularlyReservation.data.name;
    this.withNotification = this.roomRegularlyReservation.data.withNotification;
    this.selectedGroupId = this.roomRegularlyReservation.data.group?.id ?? "";
    this.selectedLabelId =
      this.roomRegularlyReservation.data.labels?.[0]?.id ?? "";
    const timeTexts = dayjs(this.roomRegularlyReservation.data.nextTime * 1000)
      .locale("ja")
      .format("YYYY-MM-DD HH:mm")
      .split(" ");
    this.reserveDay = timeTexts[0];
    this.reserveTime = timeTexts[1];
    this.endReserveTime = this.roomRegularlyReservation.data.end ?? "";

    if (this.roomRegularlyReservation.data.deletionTime) {
      this.deletionDay = dayjs(
        this.roomRegularlyReservation.data.deletionTime * 1000
      )
        .locale("ja")
        .add(-1, "day")
        .format("YYYY-MM-DD");
      this.existDeletionTime = true;
    }
    this.selectedRegularPattern =
      this.roomRegularlyReservation.data.reservationType;
  }

  close() {
    this.$emit("close");
  }
}
