
import MBaseModal from "@/components/MBaseModal.vue";
import MButton from "@/components/MButton.vue";
import MIcon from "@/components/MIcon.vue";
import MSelectBox from "@/components/form/MSelectBox.vue";
import MTextArea from "@/components/form/MTextArea.vue";
import MTextField from "@/components/form/MTextField.vue";
import { Group } from "@/entities/group";
import store from "@/store";
import dayjs from "dayjs";
import { Options, Vue } from "vue-class-component";
import { RoomReservation } from "@/entities/room_reservation";
import {
  deleteRoomReservation,
  updateRoomReservation
} from "@/api/room_reservation";
import { saveErrorLog } from "@/api/error";
import { State } from "@/store/helper";
import { Label } from "@/entities/label";
import { convertToUnixtimeFromDateAndTime } from "@/utils/date";

@Options({
  components: {
    MBaseModal,
    MButton,
    MIcon,
    MSelectBox,
    MTextArea,
    MTextField
  },
  emits: ["close"],
  props: {
    roomReservation: Object,
    groups: Array
  }
})
export default class MrEditRoomReservationModal extends Vue {
  roomReservation!: RoomReservation;
  selectedGroupId = "";
  selectedLabelId = "";
  groups: Group[] = [];
  name = "";
  withNotification = false;
  reserveDay = "";
  reserveTime = "18:00";
  endReserveDay = "";
  endReserveTime = "";

  @State("labels", "room") labels!: Label[];

  get selectedLabel(): Label | null {
    return (
      this.labels.find(label => label.ref.id === this.selectedLabelId) ?? null
    );
  }

  get validName() {
    return this.name.length > 0;
  }

  get validReserveDay(): boolean {
    return /^\d{4}-\d{2}-\d{2}$/.test(this.reserveDay);
  }

  get validReserveTime(): boolean {
    return /^\d{2}:\d{2}$/.test(this.reserveTime);
  }

  get validReserve() {
    return this.validReserveDay && this.validReserveTime;
  }

  get validData() {
    if (this.endReserveDay && !this.endReserveTime) {
      // 終了予定日付が入力されていて、終了予定時分が入力されていない場合は、開室ボタンを非活性にする
      return false;
    } else if (this.endReserveTime && !this.endReserveDay) {
      // 終了予定時分が入力されていて、終了予定日付が入力されていない場合は、開室ボタンを非活性にする
      return this.validReserve && !!this.selectedLabel && !!this.endReserveDay;
    }
    return this.validName && this.validReserve;
  }

  async updateRoomReservation() {
    if (!this.validData) {
      return;
    }

    // 開室予約の学習室開催で過去日時が入力されていたら、アラートを表示して早期リターン
    if (
      convertToUnixtimeFromDateAndTime(
        this.endReserveDay,
        this.endReserveTime
      ) < convertToUnixtimeFromDateAndTime(this.reserveDay, this.reserveTime)
    ) {
      confirm("閉室予定日時は開室予定日時より後の日時を設定してください。");
      this.endReserveDay = "";
      this.endReserveTime = "";
      return;
    }

    const matchGroups = this.groups.filter(
      group => group.ref.id === this.selectedGroupId
    );

    const reservationDateData = dayjs(
      `${this.reserveDay} ${this.reserveTime}`
    ).locale("ja");
    const reservationTime = reservationDateData.unix();

    const endReservationTime =
      !!this.endReserveDay && !!this.endReserveTime
        ? dayjs(`${this.endReserveDay} ${this.endReserveTime}`)
            .locale("ja")
            .unix()
        : null;

    store.commit("START_LOADING", "更新中...");

    try {
      await updateRoomReservation(
        this.roomReservation.ref,
        this.name,
        matchGroups.length > 0 ? matchGroups[0].data.title : "全ての生徒",
        this.withNotification,
        reservationTime,
        endReservationTime,
        this.selectedLabel ? [this.selectedLabel.ref] : [],
        matchGroups.length > 0 ? matchGroups[0].ref : undefined
      );
    } catch (e) {
      store.commit("END_LOADING");
      alert(e.message);
      await saveErrorLog(
        store.state.role,
        e.code,
        e.message,
        "Failed to update room reservation"
      );
      return;
    }

    store.commit("END_LOADING");

    this.$router.go(0);
  }

  async deleteRoomReservation() {
    store.commit("START_LOADING", "削除中...");
    try {
      await deleteRoomReservation(this.roomReservation.ref);
    } catch (e) {
      store.commit("END_LOADING");
      alert(e.message);
      await saveErrorLog(
        store.state.role,
        e.code,
        e.message,
        "Failed to delete room reservation"
      );
    }
    store.commit("END_LOADING");

    this.$router.go(0);
  }

  created() {
    this.name = this.roomReservation.data.name;
    this.withNotification = this.roomReservation.data.withNotification;
    this.selectedGroupId = this.roomReservation.data.group?.id ?? "";
    this.selectedLabelId = this.roomReservation.data.labels?.[0]?.id ?? "";
    const timeTexts = dayjs(this.roomReservation.data.reservationTime * 1000)
      .locale("ja")
      .format("YYYY-MM-DD HH:mm")
      .split(" ");
    this.reserveDay = timeTexts[0];
    this.reserveTime = timeTexts[1];
    if (this.roomReservation.data.end) {
      const endTimeTexts = dayjs(this.roomReservation.data.end * 1000)
        .locale("ja")
        .format("YYYY-MM-DD HH:mm")
        .split(" ");
      this.endReserveDay = endTimeTexts[0];
      this.endReserveTime = endTimeTexts[1];
    }
  }

  close() {
    this.$emit("close");
  }
}
