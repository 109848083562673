
import { Options, Vue } from "vue-class-component";
import MButton from "@/components/MButton.vue";
import MIcon from "@/components/MIcon.vue";
import MLoadingIcon from "@/components/MLoadingIcon.vue";
import MrAddModal from "@/components/room/MrAddModal.vue";
import MrEditRoomReservationModal from "@/components/room/MrEditRoomReservationModal.vue";
import MrEditRoomRegularlyReservationModal from "@/components/room/MrEditRoomRegularlyReservationModal.vue";
import MTable from "@/components/MTable.vue";
import MNavBar from "@/components/MNavBar.vue";
import MHeaderPanel, { TabItem } from "@/components/MHeaderPanel.vue";
import { Group } from "@/entities/group";
import {
  RoomReservation,
  roomReservationCollectionKey
} from "@/entities/room_reservation";
import {
  RoomRegularlyReservation,
  roomRegularlyReservationCollectionKey
} from "@/entities/room_regularly_reservation";
import { School } from "@/entities/school";
import { Target } from "@/entities/target";
import { Getter, State } from "@/store/helper";
import store from "@/store";
import {
  convertToRoomRegularlyReservationTableData,
  convertToRoomReservationTableData,
  roomReservationsTableDefinition
} from "@/utils/table";

@Options({
  components: {
    MButton,
    MIcon,
    MLoadingIcon,
    MTable,
    MNavBar,
    MHeaderPanel,
    MrAddModal,
    MrEditRoomReservationModal,
    MrEditRoomRegularlyReservationModal
  }
})
export default class RoomReservationList extends Vue {
  tabs: TabItem[] = [
    {
      text: "開室中",
      link: "/room"
    },
    {
      text: "今後の予定",
      link: "/room/future"
    },
    {
      text: "入室中生徒",
      link: "/room/student"
    }
  ];
  currentTabIndex = 1;
  searchKeyword = "";
  load = false;
  selectedReservationId = "";
  isDisplayedAddRoomModal = false;
  isDisplayedEditReservationModal = false;
  isDisplayedEditRegularlyReservationModal = false;
  definitions = roomReservationsTableDefinition;

  @State("roomReservations", "room") roomReservations!: RoomReservation[];
  @State("roomRegularlyReservations", "room")
  roomRegularlyReservations!: RoomRegularlyReservation[];

  @State("school") school!: School | null;
  @State("groups") groups!: Group[];
  @State("targets") targets!: Target[];
  @Getter("isAdmin") isAdmin!: boolean;
  @Getter("allowCreateRoom") allowCreateRoom!: boolean;

  get selectedRoomReservation(): RoomReservation | null {
    const matchReservations = this.roomReservations.filter(
      item => item.ref.id === this.selectedReservationId
    );
    if (matchReservations.length > 0) {
      return matchReservations[0];
    } else {
      return null;
    }
  }

  get selectedRoomRegularlyReservation(): RoomRegularlyReservation | null {
    const matchReservations = this.roomRegularlyReservations.filter(
      item => item.ref.id === this.selectedReservationId
    );
    if (matchReservations.length > 0) {
      return matchReservations[0];
    } else {
      return null;
    }
  }

  get datas(): { [key: string]: string | number | boolean }[] {
    return [
      ...this.roomReservations.map(item =>
        convertToRoomReservationTableData(item)
      ),
      ...this.roomRegularlyReservations.map(item =>
        convertToRoomRegularlyReservationTableData(item)
      )
    ];
  }

  get filteredDatas(): { [key: string]: string | number | boolean }[] {
    if (this.searchKeyword.length === 0) {
      return this.datas;
    }

    return this.datas.filter(
      data =>
        Object.keys(data).includes("name") &&
        data.name
          .toString()
          .toLowerCase()
          .includes(this.searchKeyword.toLowerCase())
    );
  }

  showEditModal(id: string) {
    const ids = id.split("/");
    if (ids.length !== 2) {
      return;
    }

    if (ids[0] === roomReservationCollectionKey) {
      this.selectedReservationId = ids[1];
      this.isDisplayedEditReservationModal = true;
    } else if (ids[0] === roomRegularlyReservationCollectionKey) {
      this.selectedReservationId = ids[1];
      this.isDisplayedEditRegularlyReservationModal = true;
    }
  }

  created() {
    store.dispatch("room/setup");
  }
}
